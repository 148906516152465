
                                @use "~Styles/includes/mixins";
                                @use "~Styles/includes/variables";
                            
.account_page {
  background-color: #f7f7f7;

  .container {
    padding: 0;
  }

  .accent_bar {
    display: none;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background: linear-gradient(
      to right,
      #84B7E2, #84B7E2 6.49%,
      #F1AD00 6.49%, #F1AD00 16.23%,
      #327882 16.23%, #327882 29.21%,
      #0D384A 12.21%, #0D384A 68.72%,
      #8BAAAD 68.72%, #8BAAAD 84.95%,
      #327882 84.95%, #327882 90.36%,
      #F1AD00 90.36%, #F1AD00 100%,
    );

    @include mixins.min-screen(variables.$md) {
      & {
        display: block;
        visibility: visible;
      }
    }
  }

  &__content {
    width: 100%;
    max-width: 390px;
    padding: 16px 24px 40px;
    margin: 0 auto;
    background-color: #fff;
    font-family: variables.$fontQuaternary;

    @include mixins.min-screen(variables.$md) {
      max-width: 502px;
      padding: 40px;
    }
  }


  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 390px;
    padding: 16px 0;
    margin: 0 auto;
    background-color: #fff;

    @include mixins.min-screen(variables.$md) {
      max-width: 502px;
      padding: 40px 0;
      background-color: transparent;
    }

    a {
      font-size: 0;
    }

    svg {
      width: 84px;
      margin: 0 auto;

      @include mixins.min-screen(variables.$md) {
        width: 105px;
      }

    }
  }

  &__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.002em;
    text-align: center;
    margin: 0;
  }
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  &--login {
    margin: 24px 0 0;

    .form__btn {
      &--primary {
        margin: 27px 0 0;
      }
    }
  }

  &--registration {
    margin: 24px 0 0;

    .form__btn {
      &--primary {
        margin: 12px 0 0;
      }
    }
  }

  &__input_group {
    position: relative;

    &.is-multi-column {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 12px;
    }
  }

  &__input_frame {
    position: relative;
    width: 100%;

    .is-multi-column & + .form__input_wrap {
      margin: 0;
    }
  }

  &__input_wrap {
    position: relative;
    display: flex;
    width: 100%;
    height: 56px;
    padding: 8px 16px;
    background-color: #f7f7f7;
    border-radius: 12px;

    &.has-error {
      border: 1.5px solid #BF0726;
    }

    .is-multi-column & + .form__input_wrap {
      margin: 0;
    }

    &.no-pointer-events {
      pointer-events: none;
    }

  }

  &__helper_btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 42px;
    padding: 0;
    border: none;
    background-color: transparent;
    appearance: none;
    outline: none !important;
    box-shadow: none !important;
    cursor: pointer;

    .icon {
      font-size: 20px;
    }

    &--reveal_password {
      &:not(.is-active) {
        .icon-eye-off {
          display: none;
          visibility: hidden;
        }
      }

      &.is-active {
        .icon-eye-on {
          display: none;
          visibility: hidden;
        }
      }
    }

    &--toggle_select {
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;

      .icon {
        font-size: 20px;
      }
    }

  }

  input {
    position: absolute;
    right: 16px;
    bottom: 8px;
    left: 16px;
    height: 22px;
    width: auto;
    padding: 0;
    appearance: none;
    background-color: transparent;
    border: none;
    font-size: 16px;
    line-height: 22px;
    color: #000 !important;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:focus,
    &:active {
      background-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &::placeholder,
    &::-webkit-input-placeholder {
      display: none;
      visibility: hidden;
    }
  }

  select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    appearance: none;
    opacity: 0;
    cursor: pointer;
  }

  &__select_value {
    position: absolute;
    right: 16px;
    bottom: 8px;
    left: 16px;
    height: 22px;
    width: auto;
    padding: 0;
    appearance: none;
    background-color: transparent;
    border: none;
    font-size: 16px;
    line-height: 22px;
    color: #000 !important;
    pointer-events: none;
  }

  &__label {
    display: flex;
    align-self: center;
    height: 40px;
    margin: 0;
    font-size: 16px;
    line-height: 40px;
    color: #737373;
    transition: all 0.15s;

    .form__input_wrap.has-value input + &,
    .form__input_wrap.has-value select + &,
    .form__input_wrap input:active + &,
    .form__input_wrap input:focus + & {
      align-self: start;
      height: 16px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__field_error,
  &__field_hint {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 0.001em;
  }

  &__field_error {
    display: none;
    color: #bf0726;

    .form__input_wrap.has-error ~ & {
      display: block;
    }
  }

  &__field_hint {
    color: #737373;

    .form__input_wrap.has-error ~ & {
      display: none;
    }
  }

  &__link {
    display: inline-block;
    margin: 23px auto 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.005em;
    color: #327882;
  }

  &__disclaimer {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0005em;
    color: #737373;
    text-align: center;

    .form--registration & {
      margin: 28px 0 0;
    }

    a {
      color: #327882;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    border-radius: 100px;
    border: none;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: -0.005em;
    text-decoration: none !important;
    appearance: none;
    outline: none !important;
    transition: all 0.25s;
    cursor: pointer;

    &--primary {
      background-color: #327882;
      color: #fff;

      &:hover {
        background-color: #47858E;
      }

      &:focus {
        background-color: #2D6C75;
      }
    }

    &--secondary {
      background-color: #fff;
      border: 1.5px solid #D9D9D9;
      color: #2e2e2e !important;

      &:hover {
        border-color: #B0B0B0;
      }
      &:focus {
        background-color: #F7F7F7;
      }
    }
  }

  &__global_error {
    margin: 4px 0 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.001em;
    color: #bf0726;
  }
}
