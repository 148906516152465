@use "typography_definitions";

// Breakpoints
$sm: 576px;
$smd: 768px;
$md: 992px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1280px;

// Font families
$fontPrimary: "Open Sans", sans-serif;
$fontSecondary: "Lato", sans-serif;
$fontTertiary: "Montserrat", sans-serif;
$fontQuaternary: "Moderat", sans-serif;

//colors
$colors: (
    neutral: (
        300: #c7c7cc,
        700: #373c3d,
        800: #232525,
        teal-900: #083f47,
    ),
    bg: (
        solid: (
            primary: #fff,
            secondary: #f7f9f9,
        ),
        transparent: (
            tertiary: #666d6e17,
            quaternary: #666d6e30,
        ),
        quaternary: #ededf2,
        secondary: #f7f7fa,
    ),
    text: (
        primary: #18191a,
        secondary: #484F50,
        tertiary: #717175,
        inverse: #fff,
        quaternary: #8e8e93,
    ),
    button: (
        primary: (
            bg: (
                default: #327882,
                hover: #51969f,
                pressed: #206771,
                disabled: #ced1d2,
            ),
            text: (
                default: #ffffff,
                hover: #ffffff,
                pressed: #ffffff,
                disabled: #ffffff,
            )
        ),
        secondary: (
            bg: (
                default: #ffffff,
                hover: #ffffff,
                pressed: #f1f3f3,
                disabled: #ffffff,
            ),
            text: (
                default: #0d0e0e,
                hover: #0d0e0e,
                pressed: #0d0e0e,
                disabled: #ced1d2,
            )
        ),
        tertiary: (
            bg: (
                default: #ffffff00,
                hover: #666d6e17,
                pressed: #666d6e30,
                disabled: #ffffff00,
            ),
            text: (
                default: #0d0e0e,
                hover: #0d0e0e,
                pressed: #0d0e0e,
                disabled: #ced1d2,
            )
        ),
        danger: (
            bg: (
                default: #FF506729,
                hover: #FF506742,
                pressed: #FF506742,
                disabled: #ced1d2,
            ),
            text: (
                default: #DB1F41,
                hover: #DB1F41,
                pressed: #DB1F41,
                disabled: #ffffff,
            )
        ),
        text: (
            secondary: (
                default: #000000,
            ),
        ),
        background: (
            secondary: (
                default: #f2f2f7,
                hover: #ededf2,
            ),
            tertiary: (
                hover: #666d6e17,
            ),
        ),
    ),
    border: (
        solid: (
            secondary: #e0e3e3,
            quaternary: #979fa0,
        ),
        transparent: (
            primary: #666d6e17,
            secondary: #666d6e30,
        )
    ),
    accent: (
        primary: #327882,
    ),
    status: (
        info: (
            bg: #9ed7ff52,
            text-primary: #336688,
        ),
        warning: (
            bg: #fbcd5352,
            text-secondary: #d36c1f,
        ),
        error: (
            bg: #ff506729,
            text-secondary: #db1f41,
        )
    ),
    divider: #666d6e30,
);

// Shadows
$boxShadowLarge: 0px 6px 24px 0px rgba(0, 0, 0, 0.08);
$boxShadow2XLarge: 0px 16px 64px 0px rgba(0, 0, 0, 0.08);
$boxShadowXSmall: 0px 0px 3px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
